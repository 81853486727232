import { API } from 'utils/api';

//Setting up withCree=dentials:true as need the cookies set by this api response on other api
export const login = async (data) => {
	const res = await API.post('rest/v1/sessions/login', data, {
		withCredentials:true
	});
	return res.data;
};

export const tmoreLogin = async (data) => {
	const res = await API.post('rest/v1/sessions/teachmore/login', data);
	return res.data;
};
