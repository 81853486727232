import _ from 'lodash';

/* eslint-disable no-undef */
const { AndroidFunction } = window;

export const showAppToast = (toastMsg) => {
	try {
		if (AndroidFunction && AndroidFunction.showToast) {
			AndroidFunction.showToast(toastMsg);
			return true;
		} else if (window.webkit?.messageHandlers?.iOSNative?.postMessage) {
			window.webkit.messageHandlers.iOSNative.postMessage({
				toastMsg: toastMsg,
			});
			return true;
		}
	} catch (error) {
		console.error(error);
	}

	return false;
};

const nameToPackage = {
	whatsapp: 'com.whatsapp',
	telegram: 'org.telegram.messenger',
};

export const shareViaExternalApp = (text, appName) => {
	return (e) => {
		if (AndroidFunction?.shareIntentWithPackage) {
			e?.preventDefault?.();
			AndroidFunction.shareIntentWithPackage(text, nameToPackage[appName]);
		}
	};
};

export const copyToAppClipBoard = (text) => {
	AndroidFunction?.copyText?.(text);
};

export const logToApp = ({
	eventId,
	eventData,
	sendToAppFlyr = false,
	sendToFirebase = false,
}) => {
	AndroidFunction &&
		AndroidFunction.logEvent &&
		AndroidFunction.logEvent(
			eventId,
			JSON.stringify(eventData),
			sendToAppFlyr,
			sendToFirebase,
		); // logEvent(eventId: String, data: Map<String, String>, appFlyer: Boolean, fireBase: Boolean)
};

export const exitAssessment = () => {
	const {
		state: {
			source,
			classRoomId,
			lessonId,
			isTuitionAppUser,
			redirectUri,
		} = {},
	} = JSON.parse(window.localStorage?.getItem('user') || '{}');

	if (source === 'webview') {
		try {
			AndroidFunction &&
				AndroidFunction.onBackPressed &&
				AndroidFunction.onBackPressed();
		} catch (e) {
			console.error(e);
		}
	} else if (window.webkit?.messageHandlers?.iOSNative?.postMessage)
		window.webkit.messageHandlers.iOSNative.postMessage({
			backAction: true,
		});
	else if (redirectUri) window.location.href = redirectUri;
	else
		window.location.href = `${process.env.REACT_APP_TM_URL}${
			isTuitionAppUser ? '/ni' : ''
		}/dashboard?tm_action=assessment_test&class_id=${classRoomId}&lesson_id=${lessonId}`;
};

export const setMaterialCreationSuccessValue = (obj) => {
	AndroidFunction &&
		AndroidFunction.setMaterialCreationSuccessValue &&
		AndroidFunction.setMaterialCreationSuccessValue(obj);
};

const PATHS_TO_EXIT_FROM = [
	`^${process.env.PUBLIC_URL}/?$`,
	`^${process.env.PUBLIC_URL}/test/new/?$`,
	`^${process.env.PUBLIC_URL}/test/[a-zA-Z0-9]*/?$`,
	`^${process.env.PUBLIC_URL}/homework/[a-zA-Z0-9]*/?$`,
	`^${process.env.PUBLIC_URL}/paper/[a-zA-Z0-9]*/?$`,
	`^${process.env.PUBLIC_URL}/exam/[a-zA-Z0-9]*/result$`,
	`^${process.env.PUBLIC_URL}/tfi(/exam-report)?/[a-zA-Z0-9]*/?$`,
];

export const navigationHandler = (navigate) => {
	if (
		PATHS_TO_EXIT_FROM.some((p) => new RegExp(p).test(window.location.pathname))
	) {
		exitAssessment();
	} else {
		navigate(-1);
	}
	console.log('Native back pressed');
};
