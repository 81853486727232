import { Routes, Route, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useEffect, lazy, Suspense } from 'react';
import useQuery from 'hooks/useQuery';
import useStore from 'stores/user';
import { navigationHandler } from 'utils/webviewHook';
import Spinner from 'components/Spinner';

const Attempt = lazy(() => import('features/attempt/Attempt'));
const Evaluation = lazy(() =>
	import('features/ReportDashboard/Evaluation/evaluation'),
);
const StudentReport = lazy(() => import('features/attempt/Report/Student'));
const TeacherReport = lazy(() => import('features/attempt/Report/Teacher'));
const Ranking = lazy(() => import('features/attempt/Ranking'));
const Teachmore = lazy(() => import('features/teachmore'));
const TestInfo = lazy(() => import('features/attempt/TestInfo'));
const AttemptWrapper = lazy(() => import('features/attempt/Attempt/Wrapper'));
const Test = lazy(() => import('features/test'));
const TFI = lazy(() => import('features/tfi'));
const EvaluationStudentReport = lazy(() =>
	import(
		'features/ReportDashboard/Evaluation/EvaluationStudentReport/EvaluationStudentReport'
	),
);
const HomeWork = lazy(()=>import('features/HomeWork'));

axios.defaults.withCredentials = true;

const WebviewRedirect = () => {
	const query = useQuery();
	const navigate = useNavigate();
	const [initializeSession] = useStore((state) => [state.initializeSession]);

	useEffect(() => {
		initializeSession(query).then(({ user, classRoomId }) => {
			const action = query.get('action');
			const source = query.get('source');
			if (['web', 'webview'].includes(source)) {
				if (action === 'test_create') {
					navigate(`/test/new`);
				}
				const tfileId = query.get('assessment_id');
				if (
					['test_edit', 'test_submissions', 'test_attempt'].includes(action)
				) {
					if (user.role === 'teacher') {
						navigate(`/test/${tfileId}`);
					} else {
						navigate(`/paper/${tfileId}`);
					}
				}
				if (action === 'attempt') {
					navigate(`/attempt/${tfileId}`);
				}
			}
		});
	}, []);

	return null;
};

function App() {
	const query = useQuery();
	const [setClassRoomHeader] = useStore((state) => [state.setClassRoomHeader]);
	const navigate = useNavigate();

	if (process.env.REACT_APP_ENV !== 'production') {
		console.log("🚀 ~ URL -> ", window.location.href)
	}

	useEffect(() => {
		setClassRoomHeader(query);
		window.onNativeBack = () => navigationHandler(navigate);
	}, []);

	return (
		<Suspense fallback={<Spinner className='main-spinner' spinning />}>
			<Routes>
				<Route path='/' exact element={<WebviewRedirect />} />
				<Route path='/attempt/:tFileId' element={<AttemptWrapper />} />
				<Route path='test/*' element={<Test />} />
				<Route path='homework/*' element={<HomeWork />} />
				<Route path='exam/:examId/attempt' element={<Attempt />} />
				<Route path='exam/:examId/result' element={<StudentReport />} />
				<Route path='paper/:paperId/leaderboard' element={<Ranking />} />
				<Route path='paper/:paperId/result' element={<TeacherReport />} />
				<Route path='paper/:tFileId' element={<TestInfo />} />
				<Route path='evaluation/:paperId' element={<Evaluation />} />
				<Route
					path='evaluation/student/:examId/:name'
					element={<EvaluationStudentReport teacherView={true} />}
				/>
				<Route path='tfi/*' element={<TFI />} />
				<Route path='teachmore/*' element={<Teachmore />} />
			</Routes>
		</Suspense>
	);
}

export default App;
