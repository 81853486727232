import './error-tracking';
import React from 'react';
import ReactDOM from 'react-dom';
import './main.css';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import SnackbarProvider from 'react-simple-snackbar';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';

ReactDOM.render(
	<React.StrictMode>
		<BrowserRouter basename={process.env.PUBLIC_URL}>
			<SnackbarProvider>
				<MuiPickersUtilsProvider utils={MomentUtils}>
					<App />
				</MuiPickersUtilsProvider>
			</SnackbarProvider>
		</BrowserRouter>
	</React.StrictMode>,
	document.getElementById('root'),
);

reportWebVitals();
