import s from './index.module.scss';
import cx from 'classnames';

const Spinner = ({ children, spinning, className, ...props }) => (
	<div className={cx(s.cont, className)} {...props}>
		{children}
		{spinning && (
			<div className={s.ldsRing}>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
			</div>
		)}
	</div>
);

export default Spinner;
